import React, { useEffect } from 'react';
import { Router, navigate } from '@reach/router';
import { BaseStyles } from '@sumup/circuit-ui';
import { ROUTES_PATH } from 'constants/routes';
import { isUserLogged } from 'services/AuthenticationService';
import { StatusMessageProvider } from 'components/StatusMessageProvider';
import Login from 'pages/Login';
import TestConfigurationList from 'pages/TestConfigurations';
import ProtectedRoute from 'components/ProtectedRoute';
import CardReadersList from 'pages/CardReadersList';
import ReaderConfigurations from 'pages/ReaderConfigurations';
import SerialNumberSearch from 'pages/SerialNumberSearch';
import CarrierSuggestion from 'pages/CarrierSuggestion';
import UploadCarrierSuggestion from 'pages/UploadCarrierSuggestion';
import UploadSimCards from 'pages/UploadSimCards';
import ReverseLogisticsFileConverter from 'pages/ReverseLogisticsFileConverter';
import SimReactivationRequests from 'pages/SimReactivationRequests';

const Home = () => {
  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    !isUserLogged() && navigate(ROUTES_PATH.LOGIN);
  });

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    window.addEventListener('storage', () => navigate(ROUTES_PATH.LOGIN));
  });

  return (
    <StatusMessageProvider>
      <BaseStyles />
      <Router>
        <Login default path={ROUTES_PATH.LOGIN} />
        <ProtectedRoute
          // eslint-disable-next-line jsx-a11y/aria-role
          role="test_configuration"
          component={TestConfigurationList}
          path={`${ROUTES_PATH.TEST_CONFIGURATIONS}/solo`}
        />
        <ProtectedRoute
          // eslint-disable-next-line jsx-a11y/aria-role
          role="card_readers_configuration"
          component={CardReadersList}
          path={ROUTES_PATH.CARD_READERS_CONFIGURATION}
        />
        <ProtectedRoute
          // eslint-disable-next-line jsx-a11y/aria-role
          role="card_readers_configuration"
          component={ReaderConfigurations}
          path={`${ROUTES_PATH.CARD_READERS_CONFIGURATION}/:cardReader`}
        />
        <ProtectedRoute
          // eslint-disable-next-line jsx-a11y/aria-role
          role="card_reader_troubleshooting"
          component={SerialNumberSearch}
          path={ROUTES_PATH.SERIAL_NUMBER_SEARCH.ROOT}
        />
        <ProtectedRoute
          component={SerialNumberSearch}
          // eslint-disable-next-line jsx-a11y/aria-role
          role="card_reader_troubleshooting"
          path={`${ROUTES_PATH.SERIAL_NUMBER_SEARCH.SEARCH}/:initialSerialNumber`}
        />
        <ProtectedRoute
          component={CarrierSuggestion}
          // eslint-disable-next-line jsx-a11y/aria-role
          role="sim_cards_management"
          path={ROUTES_PATH.CARRIER_SUGGESTION}
        />
        <ProtectedRoute
          component={UploadCarrierSuggestion}
          // eslint-disable-next-line jsx-a11y/aria-role
          role="sim_cards_management"
          path={ROUTES_PATH.UPLOAD_CARRIER_SUGGESTION}
        />
        <ProtectedRoute
          component={UploadSimCards}
          // eslint-disable-next-line jsx-a11y/aria-role
          role="sim_cards_management"
          path={ROUTES_PATH.UPLOAD_SIM_CARDS}
        />
        <ProtectedRoute
          component={ReverseLogisticsFileConverter}
          // eslint-disable-next-line jsx-a11y/aria-role
          role="sim_cards_management"
          path={ROUTES_PATH.REVERSE_LOGISTICS_FILE_CONVERTER}
        />
        <ProtectedRoute
          component={SimReactivationRequests}
          // eslint-disable-next-line jsx-a11y/aria-role
          role="sim_cards_management"
          path={ROUTES_PATH.SIM_REACTIVATION_REQUESTS}
        />
      </Router>
    </StatusMessageProvider>
  );
};

export default Home;
